@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* Theme colors should inherit these colors directly */
  --riskfactor-50: #fff;
  --riskfactor-100: #f5f5f5;
  --riskfactor-200: #ededed;
  --riskfactor-300: #d1d6d9;
  --riskfactor-400: #71818e;
  --riskfactor-500: #445563;
  --riskfactor-600: #2c3740;
  --riskfactor-700: #252f37;
  --floodfactor-50: #cfe8ff;
  --floodfactor-100: #a1d3ff;
  --floodfactor-200: #58b4ff;
  --floodfactor-300: #2491ff;
  --floodfactor-400: #2672de;
  --floodfactor-500: #0050d8;
  --floodfactor-600: #222fbf;
  --floodfactor-700: #212463;
  --firefactor-50: #fef0c8;
  --firefactor-100: #ffe396;
  --firefactor-200: #ffbe2e;
  --firefactor-300: #f3971e;
  --firefactor-400: #e66f0e;
  --firefactor-500: #d54309;
  --firefactor-600: #9c3d10;
  --firefactor-700: #632713;
  --heatfactor-50: #fee0d8;
  --heatfactor-100: #ffb8a3;
  --heatfactor-200: #fc8871;
  --heatfactor-300: #f75565;
  --heatfactor-400: #e13759;
  --heatfactor-500: #c11154;
  --heatfactor-600: #8a1b36;
  --heatfactor-700: #561420;
  --green-400: #39a129;
  --green-500: #10b981;
  --green-600: #059669;
  --error-500: #d54309;
}

/* TEMPORARY until we fix tailwind */

.border-floodfactor-500 {
  border-bottom: 2px solid var(--floodfactor-500);
}

.border-firefactor-500 {
  border-bottom: 2px solid var(--firefactor-500);
}

.border-heatfactor-500 {
  border-bottom: 2px solid var(--heatfactor-500);
}

html,
body {
  padding: 0;
  margin: 0;

  /* Set global text color */
  color: var(--riskfactor-600);

  /* No font clamping */
  @media screen and (max-width: 767px) {
    font-size: 16px;
  }

  /**
   * Linearly scale down the root font size by 25%
   * ref:https://css-tricks.com/linearly-scale-font-size-with-css-clamp-based-on-the-viewport/
   */
  @media screen and (min-width: 768px) and (max-width: 1536px) {
    font-size: clamp(0.75rem, 0.5rem + 0.5208vw, 1rem);
  }
}

/**
  * Anchor tag spacing
  */
[id]::before {
  content: '';
  display: block;
  height: 120px;
  margin-top: -120px;
  visibility: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.tocBlock {
  padding-bottom: 40px;

  /* risk-factor-300 */
  border-bottom: 1px solid #d1d6d9;
  margin-bottom: 40px;
}

/* .tocBlock:last-child {
  border-bottom: none;
} */

/**
 * Default Mapbox style overrides
 */

/* stylelint-disable-next-line */
.mapboxgl-ctrl-group {
  line-height: 0 !important;
}

/* stylelint-disable-next-line */
.mapboxgl-ctrl-group button {
  display: inline-block !important;
}

/* stylelint-disable-next-line */
.mapboxgl-ctrl-group button + button {
  border-top: none !important;
  border-left: 1px solid #ddd;
}

@layer utilities {
  .overflow-unset {
    overflow: unset;
  }
}

.fs-map .mapboxgl-popup {
  padding-bottom: 2.5rem;
}

.fs-map .mapboxgl-popup-content {
  padding: 0.75rem 1.1rem;
  max-width: 9.5rem;
  border-radius: 0;
}

.fs-map .mapboxgl-popup-close-button {
  font-size: 0.75rem;
  top: 0.25rem;
  right: 0.3rem;
  line-height: 1;
}

/*
 * Default react-spring-bottom-sheet style overrides
 */
[data-rsbs-overlay] {
  border-radius: 0 !important;
}

[data-rsbs-header] {
  display: none;
  box-shadow: none !important;
  padding-bottom: 0 !important;
  text-align: left !important;
}

[data-rsbs-content],
[data-rsbs-scroll] {
  overflow: visible !important;
}
